import { Button } from "../../../../Components/button";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
export default function CTA() {
  return (
    <div className="lg:mt-20 xl:mt-60">
      <div className="lg:px- px- mx-auto max-w-7xl  py-16">
        <div className="relative  rounded-lg bg-white/5 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">
                <span className="block">Ready to Meet Your Waifu?</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-white/40">
                Our smartest AI hentai lets you chat with your favourite anime
                character and also lets you generate images, you can also create
                your own waifu and start chatting with them.
              </p>
              <Button className="mt-8 text-lg">Get started for free</Button>
            </div>
          </div>
          <div className=" bottom-0 right-0 lg:absolute  ">
            <div className="flex items-center justify-center lg:items-end lg:justify-end">
              <img
                className="w-full transform rounded-md  object-cover object-left-top sm:w-2/3 xl:w-full"
                src="/ai-girlfriend-images/cta.png"
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
