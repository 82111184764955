import React from "react";
import { Route, Routes } from "react-router-dom";
import TPL01 from "./Views/TPL01";
import HentaiGirlfriend from "./Views/Hentai";
import TPL02 from "./Views/TPL02";

export default function Main() {
  return (
    <div>
      <Routes>
        <Route path="/" index element={<HentaiGirlfriend />} />
        <Route path="/tpl01" index element={<TPL01 />} />
        <Route path="/tpl02" index element={<TPL02 />} />
      </Routes>
    </div>
  );
}
